/* eslint-disable consistent-return */
// Lib dependencies
import { useNavigate, useLocation } from 'react-router-dom';

// Others
import useStore from '../shared/store';

import routesPaths from '../router/configs/routesPaths.configs';

/* -------------------------------------------------------------------------- */
/*                                    Hook                                    */
/* -------------------------------------------------------------------------- */

const useRouterNavigation = () => {
  const { entity } = useStore();

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Navigates to the specified path.
   *
   * @param {string} targetPath - The path to navigate to.
   */
  const navigateTo = (targetPath) => {
    if (targetPath === '/404') {
      return navigate(targetPath);
    }

    let queryParam = '';

    // Case entity data has been loaded => get UID from loaded data and append it to the target path
    if (entity.isLoaded) {
      queryParam = `?UID=${entity.data.reference}`;
    }

    // Case entity data is not found => extract the UID from the current path and append it to the target path
    if (!entity.isLoaded) {
      const UID = new URLSearchParams(location.search).get('UID')?.trim();
      queryParam = UID ? `?UID=${UID}` : '';
    }

    const fullPath = `${targetPath}${queryParam}`;

    navigate(fullPath);
  };

  /**
   * Navigates back to the previous page.
   */
  const navigateBack = () => {
    navigate(-1);
  };

  /**
   * Opens the specified URL in a new tab.
   *
   * @param {string} targetPath - The URL to open in a new tab.
   */
  const openNewTab = (targetPath) => {
    window.open(targetPath, '_blank');
  };

  const getCurrentPath = () => {
    function camelCaseToKebabCase(input) {
      return input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }
    const keysInKebabCase = Object.keys(routesPaths).map(camelCaseToKebabCase);

    const matchingKey = keysInKebabCase.find((key) => location.pathname.includes(key));
    return matchingKey || null;
  };

  return { navigateTo, navigateBack, openNewTab, getCurrentPath };
};

export default useRouterNavigation;
